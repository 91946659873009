<template>
    <vx-card v-if="renderForm" :title="this.title" class="po-card">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex w-full vx-col sm:w-1/6">
                <span>Good Receive</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <CustomMSelect :disabled="this.isDetail" :value="data.grCode" base-url="/api/wms/v1/wms/gr-reverse/get"
                    label="custom" track-by="custom" :multiple="false" @selected="(v) => {
                    data.grCode = v
                    data.gr = v.code_gr
                    data.po = v.code_po
                }"></CustomMSelect>                
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex w-full vx-col sm:w-1/6">
                <span>Cancel Purchase Order</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <div style="width: fit-content;">
                    <vs-checkbox v-model="data.cancelPo" :disabled="this.isDetail"></vs-checkbox>
                    <div v-if="data.cancelPo" class="mt-3 ml-2 text-danger"><b>All GR related to those PO will be reversed</b></div>
                </div>
            </div>
        </div>        
        <div class="mb-6 vx-row">
            <div class="flex w-full vx-col sm:w-1/6">
                <span>Reason</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <vs-textarea v-model="data.reason" :readonly="this.isDetail" width="100%" />
            </div>
        </div>
        <div class="mb-2 vx-row">
            <div style="text-align: center; width: 100%;">
                <vs-button v-if="!cisDetail" class="mb-2 mr-3" @click="handleSubmit">Create</vs-button>
            </div>
        </div>                 
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "./components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.query.id;
        this.isDetail = (this.$route.query.mode == "detail")
        this.isApproval = (this.$route.query.isApproval == "Approval")
        if (this.isDetail){
            this.title = "Detail Good Receive Reversal"
        } else if (this.id) {
            this.title = "Edit Good Receive Reversal"
        } else if (!this.id) {
            this.title = "Good Receive Reversal"
        }        
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            id: null,
            title: "",
            isDetail: false,
            isApproval: false,
            renderForm: true,
            data : {
                grCode: null,
                gr: "",
                po:"",
                cancelPo: false,
                reason:""
            }            
        }
    },
    methods: {
        handleSubmit() {
            if (this.data.grCode == null){
                this.$vs.dialog({
                    type: 'alert',
                    color: 'danger',
                    title: 'Information',
                    text: 'GR data is required!',
                    acceptText: 'OK',
                });
            } else if (this.data.reason == "") {
                this.$vs.dialog({
                    type: 'alert',
                    color: 'danger',
                    title: 'Information',
                    text: 'Reason is required!',
                    acceptText: 'OK',
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    color: "primary",
                    title: "Confirmation",
                    text: "Are you sure Submit this data",
                    acceptText: "Yes, sure",
                    accept: () => this.acceptConfirm(),
                });
            }
        },
        acceptConfirm(){
            let formData = new FormData();            
            formData.append("data", JSON.stringify(this.data))

            this.$vs.loading()
            let http = this.$http.post("/api/wms/v1/wms/gr-reverse", formData)
            http.then((resp) => {
                console.log(resp)
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Failed`,
                        text: resp.message,
                        acceptText: "OK",
                        cancelButton: false,
                        cancelText: "",
                    });
                    // this.$vs.notify({
                    //     color: "danger",
                    //     title: "Failed",
                    //     text: resp.message,
                    //     position: "top-right",
                    //     iconPack: "feather",
                    //     icon: "icon-x-circle",
                    //     duration: 10000,
                    // });
                }
            })
        },
        handleBack() {
            this.$vs.loading();
            if (!this.isApproval) {
                this.$router.push({
                    path: '/reversal/gr'
                });
            } else {
                this.$router.push({
                    path: '/reversal/gr/approval'
                });
            }
            this.$vs.loading.close();
        },                
        customSku({ code_gr, external_code_gr, code_po, external_code_po }) {
            return `GR Code : ${code_gr} <br> GR Code External : ${external_code_gr || ''} <br> PO Code : ${code_po} <br> PO Code External : ${external_code_po || ''}`;
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`/api/wms/v1/wms/gr-reverse/`+this.id, {})
                .then((resp) => {
                    if (resp.code == 200) {                        
                        this.$vs.loading.close();
                        console.log(resp.data.data[0])
                        console.log(resp.data.countline)
                        console.log(resp.data.option[0])
                        var data = resp.data.data[0]
                        var countLine = resp.data.countline
                        var selected = resp.data.option[0]
                        this.data.reason = data.reason
                        this.data.gr = data.code_gr
                        this.data.po = data.po_code
                        this.data.cancelPo = (countLine > 1)
                        if (typeof selected === 'object' && selected !== null) {
                            selected = {
                                ...selected,
                                custom: this.customSku(selected)
                            };
                        }
                        this.data.grCode = selected
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}. Do you want to refetch the data?`,
                            accept: this.initData,
                        });
                        console.log(resp)
                    }
                });

        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formattedNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
        formatNumDec: () => {
            return (val) => {
                const parsedValue = parseFloat(val);
                const options = parsedValue % 1 === 0 ? {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                } : {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                };

                const formattedValue = parsedValue.toLocaleString("en-US", options);
                const valueWithoutCurrencySymbol = formattedValue.replace(/[^\d,.]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },        
        cisDetail() {
            return this.isDetail;
        },
        isEdit() {
            return this.id != undefined;
        }        
    },
}
</script>

<style>
.po-card .vs-input--input.normal{
    text-align: right !important;
}

.customPrompt .vs-dialog footer{
    display: none;
}

.customPrompt .vs-dialog{
    min-width: 620px !important;
}

.vs-dialog-success .vs-dialog footer .vs-button-primary{
    display: none;
}
</style>